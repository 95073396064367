import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Zero knowledge (zk) is a way to prove someone knows a certain secret but without revealing any information to the validator and the outside world.`}</p>
    <p>{`Zero knowledge proof has three characteristics:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Completeness`}</strong>{`: if the prover knows some secret, verifier can verify they indeed know the secret by doing some steps.`}</li>
      <li parentName="ul"><strong parentName="li">{`Soundness`}</strong>{`: if the prover does not know the secret, no matter how they fake the steps, the verifier would reject.`}</li>
      <li parentName="ul"><strong parentName="li">{`Zero-knowledge`}</strong>{`: While proving, the prover will leak no information about the secret.`}</li>
    </ul>
    <h2>{`Some random thinking about zk and gossip`}</h2>
    <p>{`While I was browsing Weibo, I found people like to share some hearsay to satisfy their vanity
but are afraid of leaking the critical information.`}</p>
    <p>{`Just some random thinking; a zk proof is excellent for this use case if it can be somehow proved. 🤔`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      